.radio {
  flex-basis: calc(50% - var(--betterplace-spacing-100));
}

.button {
  flex-basis: 100%;
}

.inputWrapper {
  align-self: flex-start;
}

.input {
  width: 8.75rem;
}
