@mixin mobile {
  .triggerTestButton {
    margin-block: var(--betterplace-spacing-200);
  }
}

.triggerTestButton {
  display: grid;
  place-items: center center;
  grid-template-areas: 'stack';

  .icon {
    grid-area: stack;
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--betterplace-spacing-100);
  }

  .text {
    grid-area: stack;
  }

  &.success {
    .text {
      visibility: hidden;
    }

    .icon {
      visibility: visible;
    }
  }
}
