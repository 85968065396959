.widgetContainer {
  overflow-x: scroll;
  width: 100%;
  position: relative;
  height: 170px;
  flex-shrink: 0;
  border-radius: 0.25rem;
  border: 0.075rem solid var(--betterplace-color-border-light);

  & > div {
    padding: 1rem;
  }
}
