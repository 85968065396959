.container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: var(--betterplace-spacing-300);
  min-inline-size: min-content;

  @mixin desktop {
    & > * {
      flex: 1;
      max-inline-size: var(--betterplace-measure-card-max);
      min-inline-size: var(--betterplace-measure-card-min);
    }
  }
}

.outstandingAmount {
  font: var(--betterplace-typography-functional-text-sm-default);
}
