.textAlignToggle {
  stroke: var(--betterplace-color-fg-selected);
}

.textAlignToggle:hover,
.textAlignToggle:has(input:checked) {
  stroke: var(--betterplace-color-bg-light);
}

.skeletonLabel {
  font: var(--betterplace-typography-label-sm);
}
