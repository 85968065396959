.fullWidthCopyContainer {
  width: 100%;
}

.fullWidthCopyContainer div {
  flex: 1;
}

/* Overriding a small visual issue with long descriptions */
.fullWidthCopyContainer p[id$='-description'] {
  max-inline-size: var(--betterplace-measure-body-text-lg);
}
