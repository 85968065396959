.scaleWidgetContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.25rem 0.25rem 0 0;

  margin: var(--betterplace-spacing-300);
}

.widgetPreviewAdaptiveContainer {
  height: fit-content !important;
  border-radius: var(--betterplace-border-radius-300);

  :global(.wrapper) {
    position: relative;
  }
}

.darkModeToggle {
  align-self: flex-end;
}
