.container {
  container-type: inline-size;
}

.formFooter {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.formFooterInner {
  flex-grow: 1;
  align-items: end;
}

.errorMessage {
  color: var(--betterplace-color-fg-attention);
}

@container (max-width: env(--betterplace-measure-body-text-lg)) {
  .formFooter {
    position: sticky;
    bottom: 0;
    box-shadow: var(--betterplace-shadow-floating-low-upwards);
    margin-inline: calc(-1 * var(--betterplace-spacing-300));
    background-color: var(--betterplace-color-bg-light);
    justify-content: center;
    padding: var(--betterplace-spacing-300);
  }

  .formFooterInner {
    align-items: center;
  }

  .button {
    inline-size: 100%;
  }
}

.cardList {
  padding: 0;
}
