.topActionContainer {
  padding-block-start: var(--betterplace-spacing-100);
  justify-content: space-between;
  width: 100%;
  container-type: inline-size;

  & > *:not(:first-child) {
    height: 3.125rem;
    align-self: flex-end;
  }
}
